import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setPosts } from "state";
import PostWidget from "./PostWidget";

const PostsWidget = ({ userId, isProfile = false }) => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1); // Track the current page
  const [loading, setLoading] = useState(false); // Track loading state
  const posts = useSelector((state) => state.posts || []); // Ensure posts is always an array
  const token = useSelector((state) => state.token);

  useEffect(() => {
    const fetchPosts = async () => {
      setLoading(true);

      let url = "https://petstagram-server-245edff6053f.herokuapp.com/posts";
      if (isProfile) {
        url = `https://petstagram-server-245edff6053f.herokuapp.com/posts/${userId}/posts`;
      }

      const response = await fetch(`${url}?page=${page}`, {
        method: "GET",
        headers: { Authorization: `Bearer ${token}` },
      });

      const data = await response.json();
      const reversedPosts = Array.isArray(data) ? [...data].reverse() : [];
      dispatch(setPosts({ posts: reversedPosts }));

      setLoading(false);
    };

    fetchPosts();
  }, [isProfile, userId, dispatch, token, page]);

  const handleIntersection = (entries) => {
    const target = entries[0];
    if (target.isIntersecting) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver(handleIntersection, {
      threshold: 0.1, // Trigger when 10% of the target is visible
    });

    const target = document.getElementById("load-more-trigger"); // Add an id to a component near the bottom
    if (target) {
      observer.observe(target);
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <>
      {Array.isArray(posts) &&
        posts.map(
          ({
            _id,
            userId,
            firstName,
            lastName,
            description,
            location,
            picturePath,
            userPicturePath,
            likes,
            comments,
            createdAt,
          }) => (
            <PostWidget
              key={_id}
              postId={_id}
              postUserId={userId}
              name={`${firstName} ${lastName}`}
              description={description}
              location={location}
              picturePath={picturePath}
              userPicturePath={userPicturePath}
              likes={likes}
              comments={comments}
              createdAt={createdAt}
            />
          )
        )}
        <div id="load-more-trigger" style={{ height: "10px" }}></div>
      {loading && <p>Loading more posts...</p>}
    </>
  );
};

export default PostsWidget;